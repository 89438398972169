.item{
    width: 21%;
    justify-content: center;
}
.item img{
    width: 1000px;
    /* height: 1000px; */
    height: 270px;
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
}
.item p{
    margin: 6px 0px;
}
.item-prices{
    display: flex;
    gap: 15px;
}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.item:hover{
    transform: scale(1.05);
    transition: 0.6s;
}


@media(max-width:800px){
    .item{
        width: 35%;
    }
    .item-price-old{
        font-size: 12px;
    }
}
@media(max-width:500px){
    .item p{
        font-size: 14px;
    }
    .item-price-new{
        font-size: 14px;
    }
    .item img{
        height:180px;
    }
}
@media(min-width:998px){
.item img{
    height: 270px;
}
}
@media(max-width:768px){
.item-prices{
    display: flex;
    align-items: center;
    margin:10px 0px;
}
}
