.form-container {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 145px auto 25px auto;

  }
  
  .items_form .ant-form-item-label {
    font-weight: 600;
  }
  
  .items_form .ant-input,
  .items_form .ant-input-number,
  .items_form .ant-select-selector {
    border-radius: 4px;
  }
  
  .items_form .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
    align-items: center;
    text-align: center;
  }
  
  .items_form .ant-btn-primary:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  