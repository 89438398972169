/* HeaderScroller.css */
.header-scroller {
    background-color: #f4f4f4;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    position: fixed;
    /* top: 0; */
margin-bottom: 20px;
    width: 100%;
    z-index: 2000;
    color: #333;
  }
  
  .header-scroller p {
    margin: 0;
    animation: fade-in-out 1s ease-in-out;
  }
  
  @keyframes fade-in-out {
    0% { opacity: 0; }
    25% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 0; }
  }
  @media(max-width:768px){
    .header-scroller p{
      font-size: 13px;
    }
  }