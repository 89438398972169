.productdisplay {
    width: 100% !important;
    display: flex;
    padding: 0px 12%;
    gap: 40px;
    justify-content: space-between;
    /* margin-top: 100px; */
}

.productdisplay-left {
    display: flex;
    gap: 18px;
    position: relative; /* For the zoomed image to be positioned relative to this container */
  }
  
  .productdisplay-img-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 150px;
}

.productdisplay-img {
    width: 300px;
    height: 300px;
    position: relative;
  }
  
  .productdisplay-img-list img {
    height: 110px;
    cursor: pointer;
  }
  
  .productdisplay-main-img {
    height: 500px;
    cursor: crosshair; /* Change cursor to indicate zoom */
    position: relative;
  }
  
  .productdisplay-right {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.productdisplay-right h1 {
    color: #3d3d3d;
    font-size: 30px;
    font-weight: 700;
  }
  
  .productdisplay-right-stars {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 14px;
  }
  
  .productdisplay-right-prices {
    display: flex;
    margin: 25px 0px;
    gap: 20px;
    font-size: 22px;
    font-weight: 700;
  }
  
  .productdisplay-right-price-old {
    color: #818181;
    text-decoration: line-through;
  }
  
  .productdisplay-right-price-new {
    color: #ff4141;
  }
  
  .productdisplay-right-size h1 {
    margin-top: 15px;
    color: #656565;
    font-size: 18px;
    font-weight: 600;
}
.productdisplay-right-quantity{
    margin-top: 20px ;
}
.product_back_btn{
height: 100%;
}


.productdisplay-right-sizes {
    display: flex;
    margin: 20px 0;
    gap: 15px;
  }
  
  .size-option {
    padding: 16px 20px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .size-option:hover {
    background: #f0f0f0;
  }
  
  .size-option.selected {
    background: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .size-option.disabled {
    background: #e0e0e0;
    color: #9e9e9e;
    border-color: #d0d0d0;
    cursor: not-allowed;
  }
  
  .order-quantity {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
  }

  .order-quantity-btn {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }

  .order-quantity-btn:hover {
    background-color: #0056b3;
  }

  .order-quantity-input {
    width: 50px;
    text-align: center;
    border: none;
    font-size: 20px;
  }


.add-to-cart-button {
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #ff4141;
    margin-bottom: 25px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .order-container {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .productdisplay-right-category {
    margin-top: 10px;
  }
  
  .productdisplay-right-category span {
    font-weight: 600;
  }
  
  /* Zoomed Image Container */
  .productdisplay-zoomed-img-container {
    position: absolute;
    top: 0;
    left: calc(100% + 20px); /* Place the zoomed image to the right of the main image */
    width: 500px;
    height: 500px;
    background-size: 150%; /* Adjust zoom level */
    background-repeat: no-repeat;
    border: 2px solid #fff;
    z-index: 10;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease, background-position 0.3s ease;
    pointer-events: none; /* Prevent interaction with the zoomed area */
  }
  
  /* Sxhow zoomed image on hover */
  .productdisplay-left:hover .productdisplay-zoomed-img-container {
    opacity: 1;
  }
  
  /* ----- Media Queries ------- */
  
  @media(max-width:1024px) {
    .productdisplay {
      padding: 0px 8%;
    }
    .productdisplay-img-list {
        gap: 10px;
        width: 80px;
    }
    .productdisplay-img-list img {
      height: 90px;
    }
    .productdisplay-main-img {
      height: 400px;
    }
  }
  
  @media(max-width:900px) {
    .productdisplay {
      gap: 20px;
      margin-top: 100px;
    }
    .productdisplay-left {
      gap: 10px;
    }
    .productdisplay-img-list {
      gap: 10px;
    }
    .productdisplay-img-list img {
      height: 80px;
    }
    .productdisplay-main-img {
      height: 350px;
    }
}

/* ----- media query for medium mobile screens ------- */

@media(max-width: 768px) {
    .productdisplay {
        flex-direction: column; /* Stack the left and right sections vertically */
        align-items: center; /* Center items */
        padding: 0 5%; /* Adjust padding */
        margin-top: 100px;
    }

    .productdisplay-left {
        width: 100%; /* Full width for the left section */
        justify-content: center; /* Center content */
        margin-bottom: 20px; /* Space below left section */
    }

    .productdisplay-img-list {
        gap: 10px; /* Reduce gap */
    }

    .productdisplay-main-img {
        height: 300px; /* Main image size */
        width: auto; /* Maintain aspect ratio */
        margin-bottom: 20px; /* Space below main image */
    }

    .productdisplay-right {
        width: 100%; /* Full width for the right section */
        align-items: center; /* Center content */
        margin-left: 0; /* Remove left margin */
        text-align: center; /* Center text */
    }

    .productdisplay-right h1 {
        font-size: 22px; /* Slightly smaller heading size */
        margin-top: 10px; /* Space above product name */
    }

    .productdisplay-right-prices {
        margin: 10px 0; /* Adjust margin for prices */
        font-size: 20px; /* Smaller price font size */
    }

    .productdisplay-right-price-old,
    .productdisplay-right-price-new {
        font-size: 16px; /* Smaller price font size */
    }

    .add-to-cart-button {
      padding: 10px 15px;
      width: 100%;
    }
    .order-quantity-btn{
      padding: 10px 15px;
      width: 100%;
    }
    .productdisplay-centre{
      width:281px;
    }
    .product_back_btn{
      display: flex;
      justify-content: start;
      width:100%;
      height: 100%;
    }
}


@media(max-width:425px) {
    .productdisplay {
      margin-top: 100px;
    }
    .productdisplay-right{
        margin-top: 50px;
    }
    .productdisplay-right h1 {
      font-size: 17px;
    }
    .productdisplay-right-prices {
      margin: 15px 0;
    }
    .productdisplay-right-price-old,
    .productdisplay-right-price-new {
      font-size: 18px;
    }
    .add-to-cart-button {
      padding: 10px 15px;
      width: 100%;
    }

    .productdisplay-img {
      height: 240px;
      width: 100%;
    }
    .productdisplay-main-img {
      height: 240px;
    }
    .order-quantity{
        width: 160px;
    }
    .order-quantity-btn {
      padding: 10px 15px;
      font-size: 16px;
      width: 40px;
    }
    .productdisplay-img-list img {
      padding-left: 20px;
    }
}
.productdisplay-main-video{
    height: 300px;
    width: 300px;
}


/* .productdisplay-left {
    display: flex;
  } */

  .productdisplay-img-list img {
    width: 85px;
    height: 95px;
    margin-right: 70px;
    cursor: pointer;
  }

  .productdisplay-img {
    position: relative;

  }

  .productdisplay-main-img {
    width: 100%;
    height: auto;
    position: relative;
    align-self: flex-start; /* Align the video to the top */
  }

  .productdisplay-zoomed-img {
    position: absolute;
    top: 0;
    left: 320px;
    width: 500px;
    height: 500px;
    margin-left: 20%;
    border: 2px solid #ccc;
    background-repeat: no-repeat;
    background-size: 600px 600px;
    z-index: 10;
  } 



