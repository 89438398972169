.new-collections{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 40px;
   margin-bottom: 40px;
}
.new-collections h1{
    color: #171717;
    font-size: 40px;
    font-weight: 600;
}
.new-collections hr {
    width: 150px;
    height: 4px;
    border-radius: 10px;
    background: #252525;
  }
.collections{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:40px auto 15px auto;
    gap: 30px;
    max-width: 1280px;
}
@media(max-width:768px){
    .new-collections h1{
        font-size: 30px;
        font-weight: 600;
    }
}
