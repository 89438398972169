.login {
  width: 100%;
  min-height: 80vh;
  background: #f3f4f6;
  padding-top: 100px;
}

.login-container {
  width: 90%;
  max-width: 450px;
  background: white;
  margin: auto;
  padding: 30px 40px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.login-container h1 {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
}

.login-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.input-group {
  display: flex;
  gap: 10px;
}

.input-group .country-code {
  width: 40%; /* Country code input is smaller */
}

.input-group .mobile-number {
  width: 60%; /* Mobile number takes the rest of the space */
}

.login-fields input {
  height: 50px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  color: #5c5c5c;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.login-fields input:focus {
  border-color: #ff4141;
}

.error {
  border-color: #ff4141 !important;
}

.error-message {
  color: #ff4141;
  font-size: 14px;
  margin-top: 10px;
}

.login-container button {
  width: 100%;
  height: 50px;
  color: white;
  background: #ff4141;
  margin-top: 30px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background: #e03838;
}

.login-signup {
  margin-top: 20px;
  color: #5c5c5c;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.login-signup span {
  color: #ff4141;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 600px) {
  .login-container h1 {
      font-size: 24px;
      margin: 0px;
  }

  .login-container {
      padding: 20px;
  }

  .login-fields input {
      padding-left: 10px;
      font-size: 14px;
      height: 45px;
  }

  .login-container button {
      height: 45px;
      font-size: 16px;
  }

  .input-group .country-code,
  .input-group .mobile-number {
      width: 100%; /* Stack inputs in smaller screens */
  }
}



/* .login {
    width: 100%;
    min-height: 80vh;
    background: #f3f4f6;
    padding-top: 100px;
  }
  
  .login-container {
    width: 90%;
    max-width: 450px;
    background: white;
    margin: auto;
    padding: 30px 40px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .login-container h1 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
  }
  
  .login-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  
  .login-fields input {
    height: 50px;
    width: 100%;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    color: #5c5c5c;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .login-fields input:focus {
    border-color: #ff4141;
  }
  
  .error {
    border-color: #ff4141 !important;
  }
  
  .error-message {
    color: #ff4141;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .login-container button {
    width: 100%;
    height: 50px;
    color: white;
    background: #ff4141;
    margin-top: 30px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .login-container button:hover {
    background: #e03838;
  }
  
  .login-signup {
    margin-top: 20px;
    color: #5c5c5c;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  
  .login-signup span {
    color: #ff4141;
    font-weight: 600;
    cursor: pointer;
  }
  
  @media (max-width: 600px) {
    .login-container h1 {
      font-size: 24px;
      margin: 0px;
    }
  
    .login-container {
      padding: 20px;
    }
  
    .login-fields input {
      padding-left: 10px;
      font-size: 14px;
      height: 45px;
    }
  
    .login-container button {
      height: 45px;
      font-size: 16px;
    }
  } */
  