.relatedproducts{
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px 0 10px;
}
.relatedproducts h1{
    color: #171717;
    font-size: 40px;
    font-weight: 600;
}
.relatedproducts hr{
    width: 150px;
    height: 4px;
    border-radius: 10px;
    background: #252525;
}
.relatedproducts-item{
    margin: 40px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    max-width: 1280px;
}


@media(max-width:600px){
   
    .relatedproducts h1{
        font-size: 30px;
    }
    .relatedproducts hr{
        width: 100px;
        height: 4px;
        
    }
}
