/* Container for hero section */
.hero-container {
    position: relative;
    width: 100%;
    top: 120px;
    height: 80vh;
    overflow: hidden;
  }

  /* Style the video to cover the entire hero section */
  .hero-video {
    width: 100%;
    height: 75%;   /* don't disturb this*/
    object-fit: cover;
  }

  /* Button styling */
  .control-button {
    position: absolute;
  top: 40px;
  right: 20px;
  padding: 12px 24px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  }

  .control-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  /* Responsive styling */
  @media (max-width: 1024px) {
    .hero-container {
      height: 70vh;
    }

    .control-button {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    .hero-container {
      height: 60vh;
    }

    .control-button {
      padding: 10px 20px;
      font-size: 12px;
    }
  }

  @media (max-width: 480px) {
    /* .hero-container {
      height: 50vh;
    } */

    .control-button {
      padding: 8px 16px;
      font-size: 12px;
    }
  }


/* .hero{
    min-height: 100vh;
    background:  linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 9%;
}
.hero-left{
    line-height: 1.1;
}
.hero-left h2{
    color: #090909;
    font-size: 18px;
}

.hero-left p{
    color: #171717;
    font-size: 80px;
    font-weight: 700;
}
.hero-hand-icon{
    display: flex;
    align-items: center;
    gap: 20px;
}
.hero-hand-icon img{
    width: 70px;
}
.hero-latest-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 250px;
    height: 60px;
    border-radius: 75px;
    margin-top: 20px;
    background: #ff4141;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.hero-latest-btn img{
    width: 18px;
}

.hero-right img{
    width: 450px;
}

@media(max-width:1200px){

    .hero{
        padding: 10px 6%;
    }
    .hero-left p{
        font-size: 70px;
    }
    .hero-right img{
        width: 400px;
    }

}
@media(max-width:900px){

    .hero-left p{
        font-size: 45px;
    }
    .hero-right img{
        width: 350px;
    }

}
@media(max-width:700px){
   .hero{
    padding: 0 10%;
   }
    .hero-left p{
        font-size: 55px;
    }
    .hero-right{
        display: none;
    }
    .hero-left{
        line-height: 1.2;
    }
    .hero-left h2{
        margin-bottom: 20px;
    }

    .hero-latest-btn{
        margin-top: 30px;
    }
} */
