.deliverySections{
    display:flex;
    flex-direction:row;
    margin-top: 10px;
    padding:10px 0px;
    gap:15px;
}
.items{
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    align-items:center;
    text-align:center;
    font-family:sans-serif;
}
@media (min-width:994px) {
    .deliverySections{
        gap:30px;
    }
}