/* .loginsignup{
    width: 100%;
    min-height: 90vh;
    background: #fce3fe;
    padding-top: 100px;
}
.loginsignup-container{
    width: 90%;
    max-width: 550px;
    background: white;
    margin: auto;
    padding: 30px 40px;
    box-sizing: border-box;
}
.loginsignup-container h1{
    margin: 15px 0px;
}
.loginsignup-fields{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 20px;
}
.loginsignup-fields input{
    height: 60px;
    width: 100%;
    padding-left: 20px;
    border: 1px solid #c9c9c9;
    outline: none;
    color: #5c5c5c;
    font-size: 18px;
    box-sizing: border-box;
}
.loginsignup-container button{
    width: 100%;
    height: 60px;
    color: white;
    background: #ff4141;
    margin-top: 30px;
    border: none;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
}
.loginsignup-login{
    margin-top: 20px;
    color: #5c5c5c;
    font-size: 16px;
    font-weight: 500;
}
.loginsignup-login span{
    color: #ff4141;
    font-weight: 600;
}
.loginsignup-agree{
    display: flex;
    align-items: center;
    margin-top: 18px;
    gap: 10px;
    color: #5c5c5c;
    font-size: 15px;
    font-weight: 500;
}
@media(max-width: 600px){
    .loginsignup-container h1{
        font-size: 22px;
        margin: 0px;
    }
    .loginsignup-container{
        padding: 30px 20px;
    }
    .loginsignup-fields input{
        padding-left: 10px;
        font-size: 16px;
        height: 50px;
    }
    .loginsignup-fields{
        gap: 15px;
    }
    .loginsignup-container button{
        height: 50px;
        margin-top: 20px;
        font-size: 17px;
    }
} */

.loginsignup {
    width: 100%;
    min-height: 90vh;
    background: #f3f4f6;
    padding-top: 50px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginsignup-container {
    width: 90%;
    max-width: 550px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    padding: 40px 50px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

.loginsignup-container:hover {
    transform: translateY(-5px);
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo img {
    max-width: 100%;
    height: auto;
    width: 150px; /* Default width for the logo */
  }

.loginsignup-container h1 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
}

.loginsignup-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.loginsignup-row {
    display: flex;
    gap: 15px;
}

.loginsignup-fields input {
    height: 50px;
    width: 100%;
    padding-left: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    color: #333;
    font-size: 16px;
    transition: border 0.3s ease;
}

.loginsignup-fields input:hover, 
.loginsignup-fields input:focus {
    border-color: #ff4141;
}

.loginsignup-fields input.error {
    border-color: #ff4141;
    background: #ffe6e6;
}

.error-msg {
    color: #ff4141;
    font-size: 12px;
    margin-top: -10px;
}

.loginsignup-container button {
    width: 100%;
    height: 50px;
    color: white;
    background: linear-gradient(90deg, #ff6a6a, #ff4141);
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s ease;
}

.loginsignup-container button:hover {
    background: linear-gradient(90deg, #ff4141, #ff6a6a);
}

.loginsignup-login {
    margin-top: 20px;
    color: #666;
    font-size: 14px;
    text-align: center;
}

.loginsignup-login span {
    color: #ff4141;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.3s ease;
}

.loginsignup-login span:hover {
    color: #ff6a6a;
}

.custom-checkbox {
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #ccc !important;
    border-radius: 4px !important;
    cursor: pointer !important;
  }
  
  
  .custom-checkbox:checked {
    background-color: #ff4141;
  }
  
  .custom-checkbox.error {
    outline: 1px solid #ff4141;
  }  

.loginsignup-agree {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    color: #666;
    font-size: 14px;
}

@media(max-width: 600px) {
    .loginsignup-container {
        padding: 30px 20px;
    }

    .logo img {
        width: 120px; 
      }

    .loginsignup-fields input {
        padding-left: 10px;
        font-size: 14px;
    }

    .loginsignup-container button {
        height: 45px;
        font-size: 16px;
    }
}
