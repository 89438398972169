.cartitems {
    margin: 100px 5%;
}

.cartitems hr {
    height: 2px;
    background: #e2e2e2;
    border: 0;
}

.cartitems-format-main {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 50px;
    padding: 20px 0px;
    color: #454545;
    font-size: 16px;
    font-weight: 600;
}

.cartitems-format {
    font-size: 16px;
    font-weight: 500;
}

.carticon-product-icon {
    height: 55px;
}

.cartitems-remove-icon {
    width: 12px;
    margin: 0px 30px;
    cursor: pointer;
}

.cartitems-quantity {
    width: 55px;
    height: 45px;
    border: 2px solid #ebebeb;
    background: #fff;
}

.cartitems-down {
    display: flex;
    margin: 100px 0px;
}

.cartitems-total {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* margin-right: 200px; */
    gap: 40px;
}

.cartitems-total-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
}

.cartitems-total button {
    width: 220px;
    height: 50px;
    outline: none;
    border: none;
    background: #ff5a5a;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.cartitems-promocode {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}

.cartitems-promocode p {
    color: #555;
}

.cartitems-promobox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    margin-top: 15px;
    height: 50px;
    background: #eaeaea;
}

.cartitems-promobox input {
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    flex: 1;
    height: 100%;
    padding-left: 20px;
}

.cartitems-promobox button {
    width: 130px;
    height: 100%;
    font-size: 16px;
    background: black;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}

.cartitems-promobox button:hover {
    background-color: #333;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.cartitems-promobox button:active {
    background-color: #222;
    transform: scale(0.98);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.cartitems-message {
    margin-top: 10px;
    font-weight: 500;
}

.cartitems-message.success {
    color: green;
}

.cartitems-message.error {
    color: red;
}

/* Styles for the Checkout Form */
.checkout-form {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.checkout-form h2 {
    margin-bottom: 15px;
    font-size: 30px;
    color: #454545;
    text-align: center;
}

.checkout-form input,
.checkout-form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    font-size: 16px;
}

.checkout-form button {
    padding: 10px;
    background-color: #ff5a5a; /* Matches existing button color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.checkout-form button:hover {
    background-color: #e74c3c; /* Slightly darker shade for hover */
}

/* Responsive Styles */
@media(max-width:1280px) {
    .cartitems {
        margin: 85px 2%;
    }
    .cartitems-format-main {
        grid-template-columns: 0.5fr 3fr 0.5fr 0.5fr 0.5fr 0.5fr;
        gap: 20px;
        padding: 15px 0px;
        font-size: 15px;
    }
    .cartitems-format {
        font-size: 15px;
    }
    .carticon-product-icon {
        height: 50px;
    }
    .cartitems-remove-icon {
        margin: auto;
    }
    .cartitems-quantity {
        width: 40px;
        height: 30px;
    }
    .cartitems-down {
        margin: 80px 0px;
        flex-wrap: wrap;
        gap: 40px;
    }
    .cartitems-total {
        margin: 0;
        min-width: 300px;
    }
    .cartitems-total button {
        max-width: 200px;
        height: 45px;
        font-size: 13px;
    }
    .cartitems-promobox {
        width: 90%;
        max-width: 500px;
    }
}

@media(max-width:500px) {
    .cartitems {
        margin: 130px 2%;
    }
    .cartitems-format-main {
        display: none;
    }
    .cartitems-format {
        display: grid;
    }
    .cartitems-format-main {
        grid-template-columns: 0.5fr 3fr 0.5fr;
        gap: 10px;
    }
    .cartitems-promobox input {
        width: 150px;
    }
    
    .cartitems-promobox button {
        width: 110px;
    }
}



/* 
.cartitems{
    margin: 100px 5%;
}
.cartitems hr{
    height: 2px;
    background: #e2e2e2;
    border: 0;
}
.cartitems-format-main{
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 50px;
    padding: 20px 0px;
    color: #454545;
    font-size: 16px;
    font-weight: 600;
}
.cartitems-format{
    font-size: 16px;
    font-weight: 500;
}
.carticon-product-icon{
    height: 55px;
}
.cartitems-remove-icon{
    width: 12px;
    margin: 0px 30px;
    cursor: pointer;
}
.cartitems-quantity{
    width: 55px;
    height: 45px;
    border: 2px solid #ebebeb;
    background: #fff;
}
.cartitems-down{
    display: flex;
    margin: 100px 0px;
}
.cartitems-total{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 200px;
    gap: 40px;
}
.cartitems-total-item{
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
}
.cartitems-total button{
    width: 220px;
    height: 50px;
    outline: none;
    border: none;
    background: #ff5a5a;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.cartitems-promocode{
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}
.cartitems-promocode p{
    color: #555;
}
.cartitems-promobox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    margin-top: 15px;
    height: 50px;
    background: #eaeaea;
}
.cartitems-promobox input{
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    flex: 1;
    height: 100%;
    padding-left: 20px;
}
.cartitems-promobox button {
    width: 130px;
    height: 100%;
    font-size: 16px;
    background: black;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}

.cartitems-promobox button:hover {
    background-color: #333; 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.cartitems-promobox button:active {
    background-color: #222; 
    transform: scale(0.98); 
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.cartitems-message {
    margin-top: 10px;
    font-weight: 500;
}

.cartitems-message.success {
    color: green;
}

.cartitems-message.error {
    color: red;
}
@media(max-width:1280px){
    .cartitems{
        margin: 60px 2%;
    }
    .cartitems-format-main{
        grid-template-columns: 0.5fr 3fr 0.5fr 0.5fr 0.5fr 0.5fr;
        gap: 20px;
        padding: 15px 0px;
        font-size: 15px;
    }
    .cartitems-format{
        font-size: 15px;
    }
    .carticon-product-icon{
        height: 50px;
    }
    .cartitems-remove-icon{
        margin: auto;
    }
    .cartitems-quantity{
        width: 40px;
        height: 30px;
    }
    .cartitems-down{
        margin: 80px 0px;
        flex-wrap: wrap;
        gap: 40px;
    }
    .cartitems-total{
        margin: 0;
        min-width: 300px;
    }
    .cartitems-total button{
        max-width: 200px;
        height: 45px;
        font-size: 13px;
    }
    .cartitems-promobox{
        width: 90%;
        max-width: 500px;
    }
    
}
@media(max-width:500px){
    .cartitems-format-main{
       display: none;
    }
    .cartitems-format{
        display: grid;
    }
    .cartitems-format-main{
        grid-template-columns: 0.5fr 3fr 0.5fr;
        gap: 10px;
    }
} */
